import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  closeSendMoney,
  getAthenaAnalytics,
  getCreatorAthenaAnalytics,
  getTranslationData,
  getUserProfile,
  refreshCall,
} from "../../actions";
import { RootState } from "../../configureStore";
import { RouteList } from "../RouteList";
import { StyledText, Title } from "../../utils/styledComponents";
import Loading from "../../shared/Loading";
import FloatModal from "../../shared/components/FloatModal";
import FLoatDropDown from "../../shared/components/FLoatDropDown";
import MobileNav from "../MobileNav/MobileNav";
import { CONSTANTS } from "../../utils/constants";
import { styles } from "../style";
import MainList from "./MainList";
import { getLanguageByCode } from "./helper";
import { languageFormat, languageTranslateHook } from "../helper";

function MainNav() {
  const isMobile = useMediaQuery("(max-width:900px)");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentPath = window.location.pathname;
  const athenaAccess = localStorage.getItem("lastAthenaRefreshTime") || "";
  const creatorAthenaAccess = localStorage.getItem("lastCreatorAthenaRefreshTime") || "";
  const [language, setLanguage] = useState<string>("English");
  const [isFetchAthena, setIsFetchAthena] = useState<boolean>(false);

  const [isLanguageDisable, setIsLanguageDisable] = useState<boolean>(false);
  const [accessibleRoute, setAccessibleRoute] = useState({
    accessible: false,
    routeList: RouteList,
  });
  const [subRoute, setSubRouteName] = useState<{
    display: string;
    name: string;
  }>();
  const [modelProps, setModelProps]: any = useState({
    element: <Loading />,
    name: "",
  });
  const [userName, setUserName] = useState<string>("");

  const translatedLabels: any = useSelector(
    (state: RootState) => state?.entities?.translation?.getTranslationData
  );
  const { globalModalState }: any = useSelector(
    (state: RootState) => state?.entities?.globalMoadal
  );
  const { userData } = useSelector(
    (state: RootState) => state?.entities?.userProfile
  );

  useEffect(() => {
    dispatch(getUserProfile.request({}));
    dispatch(getTranslationData.request(""));

    setInterval(() => {
      dispatch(refreshCall.request({ data: {}, isNomad: false }));
    }, 14 * 60 * 1000)
  }, []);

  window.addEventListener('message', function(event) {
    if (event.data === "logout") {
      localStorage.removeItem("lastCreatorAthenaRefreshTime");
      localStorage.removeItem("lastAthenaRefreshTime");
    }
  });

  useEffect(() => {
    if (
      userData?.data &&
      !userData?.isLoading &&
      !translatedLabels?.isLoading
    ) {
      console.log(userData);
      const access: any = userData?.data?.access;

      const Editor = access
        ?.find((route: any) => route.name === "Video editor")
        ?.subRoute?.find((subroute: any) => subroute.name === "Summary")?.access ? true : false;
      setIsFetchAthena(Editor);

      let mainAccess = access?.filter((item: any) => item.access);
      const lang = localStorage.getItem("parent-lang");
      let code: any = lang?.split("/");
      setLanguage(getLanguageByCode(code[1]) ?? "English");
      let list = mainAccess?.map((item: any) => {
        let main = item;
        let subRoute = main.subRoute?.filter((item: any) => item.access);
        return {
          ...main,
          subRoute: subRoute,
        };
      });

      let elementMap = list?.map((item: any) => {
        let route = RouteList?.find((route: any) => item.name === route.name);
        let subroute = route?.subRoute
          ?.map((sub) => {
            return item.subRoute.find(
              (accessSub: any) =>
                sub.name === accessSub?.name && accessSub?.access
            );
          })
          .filter((item) => item !== undefined);
        return { ...route, subRoute: subroute };
      });
      setAccessibleRoute({ accessible: true, routeList: elementMap });
      const { first_name, last_name } = userData?.data?.user ?? {
        first_name: "",
        last_name: "",
      };
      setUserName(`${first_name} ${last_name}`);
    }
  }, [userData?.isLoading, translatedLabels?.isLoading]);

  useEffect(() => {
    RouteList.map((item) => {
      const parentRoute = item.route;
      item.subRoute.map((sub) => {
        if (`/${parentRoute}${sub.route}` === currentPath?.toLowerCase()) {
          setSubRouteName({
            display: sub.displayName ? "block" : "none",
            name: t(sub.name),
          });
        }
      });
    });
  }, [currentPath, language]);

  useEffect(() => {
    languageTranslateHook(translatedLabels, setIsLanguageDisable)
  }, [
    translatedLabels?.isLoading,
    i18n,
    translatedLabels?.intialState?.isLoading,
    language
  ]);

  useEffect(() => {
    if (!athenaAccess && !creatorAthenaAccess && isFetchAthena) {
      dispatch(getCreatorAthenaAnalytics.request({}))
      dispatch(getAthenaAnalytics.request({}));
    }
  }, [isFetchAthena]);

  useEffect(() => {
    i18n.changeLanguage(languageFormat(language));
  }, [language]);

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(languageFormat(e.target.value));
  };

  if (isMobile) {
    return <MobileNav />;
  } else
    return (
      <Container sx={styles.containerStyles}>
        {accessibleRoute?.accessible ? (
          <Box sx={styles.container}>
            <Box sx={{ ...styles.navContainer }}>
              <Stack sx={styles.stackStyle}>
                <StyledText sx={{ ...styles.greetingStyle }}>
                  {userName}
                </StyledText>
              </Stack>
              <MainList accessibleRoute={accessibleRoute} setModelProps={setModelProps} setSubRouteName={setSubRouteName} />
            </Box>
            <Box className="overflow-y-contain" sx={styles.outletContainer}>
              <Box sx={styles.containerStyle}>
                <Box sx={styles.languageWrapper}>
                  <Box sx={styles.languageWrapper.languageContainer}>
                    <FLoatDropDown
                      list={CONSTANTS.languages}
                      label={t("Select language:")}
                      name="language"
                      onChange={handleLanguageChange}
                      value={language}
                      disabled={isLanguageDisable}
                    />
                  </Box>
                </Box>
                <Title
                  style={{ display: subRoute?.display, marginTop: "10px" }}
                >
                  {subRoute?.name === "Plan"
                    ? t("Dashboard")
                    : subRoute?.name && t(subRoute.name)}
                </Title>
                <Outlet />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box height={"100vh"}>
            <Loading />
          </Box>
        )}
        <FloatModal
          open={globalModalState?.isOpen}
          onClose={() => dispatch(closeSendMoney())}
          modalTitle={t(modelProps.name)}
          modalBody={modelProps.element}
        />
      </Container>
    );
}

export default MainNav;
